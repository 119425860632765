<template>
  <div class="container-fluid">
    <sales-invoice-form
      :loading="loading"
      :salesInvoiceData="salesInvoice"
      :formErrors="formErrors"
      @salesInvoiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSalesInvoice from "../defaultSalesInvoice";
import SalesInvoiceForm from "../partials/SalesInvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SalesInvoiceForm,
  },

  mixins: [alertLeave],

  data() {
    const salesInvoice = cloneDeep(defaultSalesInvoice);
    salesInvoice.taxes = cloneDeep(defaultTaxes);
    if (this.$currentUserIsLevelOrganization()) {
      salesInvoice.organization = {
        type: "organizations",
        id: this.$currentUserOrganizationId(),
      };
    }
    return {
      salesInvoice: salesInvoice,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(salesInvoice) {
      this.loading = true;
      const salesInvoiceData = cloneDeep(salesInvoice);
      try {
        await this.$store.dispatch("salesInvoices/add", salesInvoiceData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_ADDED"),
        });
        const salesInvoice = await this.$store.getters[
          "salesInvoices/salesInvoice"
        ];
        this.$emit("onViewSalesInvoice", salesInvoice, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
