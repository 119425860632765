<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row mb-4">
      <div class="col-12">
        <h3>{{ $t("COMMON.PAYMENTS") }}</h3>
      </div>
    </div>

    <sales-payment-list-table :filterInvoice="salesInvoice.id" />

    <div class="total-wrapper">
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-6 text-right">
            {{
              salesInvoice.total_paied !== undefined
                ? $formatCurrency(salesInvoice.total_paied)
                : null
            }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import SalesPaymentListTable from "../../SalesPaymentManagement/partials/SalesPaymentListTable.vue";

export default {
  name: "sales-invoice-view-details",

  components: { SalesPaymentListTable },

  mixins: [formMixin],

  props: ["salesInvoice"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
